<template>
    <div class="checkbox-wrapper">
        <label :for="actionItem.id" class="custom-checkbox">
            {{ actionItem.text }}
            <input :id="actionItem.id" type="checkbox" :name="actionItem.id" class="custom-checkbox__input" :value="actionItem.value" :checked="actionItem.selected" @change="chooseActionPoint(actionItem)">
            <span class="custom-checkbox__label"/>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'CheckboxLabel',
        props: {
            actionItem: Object,
        },
        methods: {
            chooseActionPoint(actionPoint) {
                this.$emit('change-action-point', {actionPoint: actionPoint});
            }
        }
    }
</script>
